import { gql } from "graphql-tag";
import { CouponPayload } from "src/api/Coupon";
import { DepartmentPayload } from "src/api/Department";
import { InvoiceItemPayload } from "src/api/InvoiceItem";
import {
  archiveMembershipOffering,
  archiveMembershipOfferingVariables,
  createMembership,
  createMembershipOffering,
  createMembershipOfferingVariables,
  createMembershipVariables,
  deleteMembershipOffering,
  deleteMembershipOfferings,
  deleteMembershipOfferingsVariables,
  deleteMembershipOfferingVariables,
  exportMembershipOfferings,
  exportMembershipOfferingsVariables,
  exportMemberships,
  exportMembershipsVariables,
  fullMembership,
  fullMembershipVariables,
  membership,
  membershipOffering,
  membershipOfferingCounts,
  membershipOfferingCountsVariables,
  membershipOfferings,
  membershipOfferingsCounts,
  membershipOfferingsCountsVariables,
  membershipOfferingsVariables,
  membershipOfferingVariables,
  memberships,
  membershipsVariables,
  membershipVariables,
  updateManyMemberships,
  updateManyMembershipsVariables,
  updateMembership,
  updateMembershipOffering,
  updateMembershipOfferingVariables,
  updateMembershipVariables,
  usaSwimmingCounts,
  usaSwimmingCountsVariables,
  usaSwimmingMembers,
  usaSwimmingMembersVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";
import { StudentInfoRequestPayload } from "./StudentInfoRequest";

export const MembershipOfferingPayload = gql`
  fragment MembershipOfferingPayload on MembershipOffering {
    id
    addons {
      id
      title
      description
      billingCategory {
        id
        title
      }
      price
    }
    agreements {
      id
      title
      description
      required
    }
    allowSharing
    autoEnroll
    capacity
    checkInsPerWeek
    checkInsPerMonth
    communications {
      id
    }
    coupons {
      ...CouponPayload
    }
    department {
      ...DepartmentPayload
    }
    description
    displayOpenSpots
    endDate
    endDays
    fees {
      id
      amount
      amountType
      title
    }
    freePresentation
    integration
    maintainWaitlist
    maxCheckIns
    maxEnrollment
    maxMembers
    memberships {
      id
      status
      roster {
        id
        firstName
        lastName
      }
    }
    participantType
    passType
    paymentFrequency
    price
    priceLevels {
      id
      number
      price
    }
    pricePerCheckIn
    program {
      id
      billingCategory {
        id
      }
      department {
        id
        title
      }
      title
      description
    }
    prorate
    prorateStartEnd
    publishDate
    recurringDate
    registrationClose
    registrationOpen
    renewalType
    startDate
    status
    studentInfoRequests {
      ...StudentInfoRequestPayload
    }
    tags {
      id
      title
    }
    title
    type
    unpublishDate
  }
  ${CouponPayload}
  ${DepartmentPayload}
  ${StudentInfoRequestPayload}
`;

export const MembershipPayload = gql`
  fragment MembershipPayload on Membership {
    id
    accountOwner {
      id
      email
      firstName
      lastName
      stripeCustomerId
      stripeToken
      students {
        id
        firstName
        lastName
      }
    }
    checkIns {
      id
      used
      userId
    }
    coupons {
      ...CouponPayload
    }
    createdAt
    customerRequest {
      id
      createdAt
      description
      finalDate
      status
      type
    }
    dateApproved
    disableAutoRecharge
    events {
      id
      createdAt
      date
      type
      value
      by {
        id
        firstName
        lastName
      }
    }
    finalDate
    invoiceItems {
      ...InvoiceItemPayload
    }
    nextInvoiceDate
    offering {
      id
      allowSharing
      department {
        id
      }
      maxCheckIns
      participantType
      passType
      paymentFrequency
      price
      pricePerCheckIn
      program {
        id
        title
      }
      renewalType
      studentInfoRequests {
        id
        description
      }
      title
      type
    }
    roster {
      id
      birthDate
      firstName
      gender
      lastName
    }
    status
    studentInfoRequestResponses {
      id
      description
      additionalResponse
      studentInfoRequest {
        id
        slug
      }
    }
  }
  ${InvoiceItemPayload}
  ${CouponPayload}
`;

export const UpdateMembershipOfferingMutation = gql`
  mutation updateMembershipOffering(
    $data: MembershipOfferingCreateInput!
    $where: UniqueInput!
  ) {
    updateMembershipOffering(data: $data, where: $where) {
      ...MembershipOfferingPayload
    }
  }
  ${MembershipOfferingPayload}
` as TypedDocumentNode<
  updateMembershipOffering,
  updateMembershipOfferingVariables
>;

export const DeleteMembershipOfferingMutation = gql`
  mutation deleteMembershipOffering($id: String!) {
    deleteMembershipOffering(where: { id: $id }) {
      id
    }
  }
` as TypedDocumentNode<
  deleteMembershipOffering,
  deleteMembershipOfferingVariables
>;

export const DeleteMembershipOfferingsMutation = gql`
  mutation deleteMembershipOfferings(
    $where: MembershipOfferingWhereInput!
    $filter: String
  ) {
    deleteMembershipOfferings(where: $where, filter: $filter) {
      count
    }
  }
` as TypedDocumentNode<
  deleteMembershipOfferings,
  deleteMembershipOfferingsVariables
>;

export const CreateMembershipOfferingMutation = gql`
  mutation createMembershipOffering($data: MembershipOfferingCreateInput!) {
    createMembershipOffering(data: $data) {
      ...MembershipOfferingPayload
    }
  }
  ${MembershipOfferingPayload}
` as TypedDocumentNode<
  createMembershipOffering,
  createMembershipOfferingVariables
>;

export const CreateMembershipMutation = gql`
  mutation createMembership($data: MembershipCreateInput!) {
    createMembership(data: $data) {
      ...MembershipPayload
      offering {
        ...MembershipOfferingPayload
      }
    }
  }
  ${MembershipPayload}
  ${MembershipOfferingPayload}
` as TypedDocumentNode<createMembership, createMembershipVariables>;

export const UpdateMembershipMutation = gql`
  mutation updateMembership(
    $data: MembershipCreateInput!
    $where: UniqueInput
  ) {
    updateMembership(data: $data, where: $where) {
      ...MembershipPayload
      offering {
        ...MembershipOfferingPayload
      }
    }
  }
  ${MembershipPayload}
  ${MembershipOfferingPayload}
` as TypedDocumentNode<updateMembership, updateMembershipVariables>;

export const MembershipsQuery = gql`
  query memberships(
    $where: MembershipWhereInput
    $skip: Int
    $take: Int
    $orderBy: DefaultOrderByInput
    $filter: String
  ) {
    memberships(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      ...MembershipPayload
    }
  }
  ${MembershipPayload}
` as TypedDocumentNode<memberships, membershipsVariables>;

export const USASwimmingMembersQuery = gql`
  query usaSwimmingMembers(
    $where: MembershipWhereInput
    $skip: Int
    $take: Int
    $orderBy: DefaultOrderByInput
    $filter: String
  ) {
    usaSwimmingMembers(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      accountOwner {
        id
        email
        firstName
        lastName
        phone
      }
      avatar {
        url
      }
      birthDate
      firstName
      gender
      lastName
      studentGroupRegistrations(
        where: {
          status: { in: [Pending, Approved, Waitlist] }
          class: { integration: USASwimming }
        }
      ) {
        id
        status
        class {
          id
          department {
            id
            title
          }
          program {
            id
            title
          }
          tags {
            id
            title
            order
          }
        }
      }
      customerServiceItems(
        where: {
          type: {
            in: [
              IntegrationExpiration
              IntegrationNewUser
              IntegrationCancel
              IntegrationMerge
            ]
          }
          unread: true
        }
      ) {
        id
        integration
        type
      }
      updatedAt
      usaSwimmingMemberId
      usaSwimmingMember
    }
  }
` as TypedDocumentNode<usaSwimmingMembers, usaSwimmingMembersVariables>;

export const MembershipQuery = gql`
  query membership($id: String) {
    membership(where: { id: $id }) {
      ...MembershipPayload
    }
  }
  ${MembershipPayload}
` as TypedDocumentNode<membership, membershipVariables>;

export const FullMembershipQuery = gql`
  query fullMembership($id: String) {
    membership(where: { id: $id }) {
      ...MembershipPayload
      offering {
        ...MembershipOfferingPayload
      }
    }
  }
  ${MembershipPayload}
  ${MembershipOfferingPayload}
` as TypedDocumentNode<fullMembership, fullMembershipVariables>;

export const MembershipOfferingsQuery = gql`
  query membershipOfferings(
    $where: MembershipOfferingWhereInput
    $filter: String
    $skip: Int
    $take: Int
    $orderBy: DefaultOrderByInput
  ) {
    membershipOfferings(
      where: $where
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      ...MembershipOfferingPayload
    }
  }
  ${MembershipOfferingPayload}
` as TypedDocumentNode<membershipOfferings, membershipOfferingsVariables>;

export const MembershipOfferingQuery = gql`
  query membershipOffering($id: String!) {
    membershipOffering(where: { id: $id }) {
      ...MembershipOfferingPayload
    }
  }
  ${MembershipOfferingPayload}
` as TypedDocumentNode<membershipOffering, membershipOfferingVariables>;

export const MembershipOfferingsCountsQuery = gql`
  query membershipOfferingsCounts(
    $where: MembershipOfferingWhereInput
    $filter: String
  ) {
    membershipOfferingsCounts(where: $where, filter: $filter) {
      count
      memberships
      members
      pending
      waitlist
      closed
      usaSwimmingMembers
    }
  }
` as TypedDocumentNode<
  membershipOfferingsCounts,
  membershipOfferingsCountsVariables
>;

export const ArchiveMembershipOfferingMutation = gql`
  mutation archiveMembershipOffering($id: ID!, $organizationId: ID!) {
    archiveMembershipOffering(id: $id, organizationId: $organizationId) {
      id
    }
  }
` as TypedDocumentNode<
  archiveMembershipOffering,
  archiveMembershipOfferingVariables
>;

export const UpdateManyMemberhipsMutation = gql`
  mutation updateManyMemberships(
    $where: MembershipWhereInput
    $data: MembershipCreateInput
    $filter: String
    $selected: [String]
  ) {
    updateManyMemberships(
      where: $where
      data: $data
      filter: $filter
      selected: $selected
    ) {
      count
    }
  }
` as TypedDocumentNode<updateManyMemberships, updateManyMembershipsVariables>;

export const MembershipOfferingCountsQuery = gql`
  query membershipOfferingCounts($id: ID!) {
    membershipOfferingCounts(id: $id) {
      active
      available
      total
      pending
      waitlist
      closed
    }
  }
` as TypedDocumentNode<
  membershipOfferingCounts,
  membershipOfferingCountsVariables
>;

export const USASwimmingCountsQuery = gql`
  query usaSwimmingCounts {
    usaSwimmingCounts {
      total
      enrolled
      pending
      waitlist
      none
      membershipTypes
    }
  }
` as TypedDocumentNode<usaSwimmingCounts, usaSwimmingCountsVariables>;

export const ExportMembershipsMutation = gql`
  mutation exportMemberships($where: MembershipWhereInput!, $filter: String) {
    exportMemberships(where: $where, filter: $filter)
  }
` as TypedDocumentNode<exportMemberships, exportMembershipsVariables>;

export const ExportMembershipOfferingsMutation = gql`
  mutation exportMembershipOfferings(
    $where: MembershipOfferingWhereInput!
    $filter: String
  ) {
    exportMembershipOfferings(where: $where, filter: $filter)
  }
` as TypedDocumentNode<
  exportMembershipOfferings,
  exportMembershipOfferingsVariables
>;
