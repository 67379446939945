export function getCurrentHost() {
  let currentHost = window.location.hostname.replace(
    /(\.captyn\.com)|(\.local\.localhost)|(\.captyn\.cloud)|(\.nytpac\.com)/,
    ""
  );

  if (
    !currentHost ||
    currentHost === "localhost" ||
    currentHost === "localhost:3000" ||
    currentHost.includes(".local") ||
    currentHost.includes("captyn.netlify.app") ||
    currentHost.includes("bs-local.com")
  ) {
    currentHost = "quest";
  }

  if (currentHost.includes("captyn-dev.netlify.app")) {
    currentHost = "seattletest";
  }

  const isApp =
    currentHost === "app" ||
    currentHost === "app-dev" ||
    currentHost === "app-demo" ||
    currentHost === "app-test";

  const isDemo = window.location.hostname.includes("nytpac");

  // if (isDemo) {
  //   if (window.sessionStorage.getItem("subdomain")) {
  //     currentHost = window.sessionStorage.getItem("subdomain");
  //   } else {
  //     currentHost = "movewaterswim";
  //   }
  // }

  return {
    currentHost,
    isApp,
    isDemo,
  };
}
