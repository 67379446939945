import { Global } from "@emotion/react";
import { transparentize } from "polished";
import { Redirect, useLocation } from "react-router-dom";
import { Header } from "src/components/header/Header";
import { SuperAdminHeader } from "src/components/header/SuperAdminHeader";
import Body from "src/components/layout/Body";
import ErrorBoundry from "src/components/layout/ErrorBoundry";
import Loading from "src/components/layout/Loading";
import Menu from "src/components/menu/Menu";
import SuperAdminMenu from "src/components/menu/SuperAdminMenu";
import { OrganizationPaymentAlert } from "src/components/organizations/OrganizationPaymentAlert";
import { useStore } from "src/stores/Store";
import { checkOrganizationPaymentAlerts } from "src/utils/checkOrganizationPaymentAlerts";
import lazyImport from "src/utils/lazyImport";
import { redirects } from "src/utils/redirects";
import { Level } from "types/code-generator";

const Kiosk = lazyImport(() => import("src/routes/kiosk/Kiosk"));
const StartSuccess = lazyImport(() => import("src/routes/start/StartSuccess"));
const MissingOrganization = lazyImport(
  () => import("src/routes/MissingOrganization")
);

interface Props {
  isSetup?: boolean;
}

export default function Layout(props: Props) {
  const store = useStore();
  const location = useLocation();

  let loadingContent;

  if (store.error) {
    if (store.error === "Error fetching Organization information.") {
      return <MissingOrganization />;
    } else {
      loadingContent = <ErrorBoundry error={new Error(store.error)} />;
    }
  } else if (!store.ready) {
    loadingContent = <Loading />;
  }

  if (store.organization) {
    const paymentAlerts = checkOrganizationPaymentAlerts({
      organization: store.organization,
    });

    // console.log("paymentAlerts", paymentAlerts);

    if (store.isAdminArea && paymentAlerts.isSuspended) {
      return <OrganizationPaymentAlert full />;
    }
  }

  if (loadingContent) {
    return (
      <div className="flex h-screen items-center justify-center">
        {loadingContent}
      </div>
    );
  }

  if (store.isPending) {
    return <StartSuccess />;
  }

  let showKiosk = Boolean(
    store.user?.level === Level.Kiosk && window.sessionStorage.getItem("kiosk")
  );

  if (
    location.pathname.startsWith("/kiosk") &&
    process.env.NODE_ENV === "development"
  ) {
    showKiosk = true;
  }

  if (showKiosk) {
    return <Kiosk />;
  }

  if (
    !store.user &&
    (location.pathname.indexOf("/admin") === 0 ||
      location.pathname.indexOf("/account") === 0) &&
    location.pathname !== "/login" &&
    location.pathname !== "/checkout/account-owner-details"
  ) {
    // console.log("redirecting", store.user);
    return <Redirect to={`/login?next=${location.pathname}`} />;
  }

  let menu = <Menu />;

  if (store.user && store.isApp && store.isSuperAdminArea) {
    menu = <SuperAdminMenu />;
  }

  if (Object.keys(redirects).includes(location.pathname)) {
    return <Redirect to={redirects[location.pathname]} />;
  }

  return (
    <>
      <Global
        styles={{
          "::selection": {
            background: transparentize(0.5, store.theme.orgColor),
          },
        }}
      />
      {!store.isSuperAdminArea && !location.pathname.startsWith("/meets/") && (
        <Header />
      )}
      {store.isSuperAdminArea && <SuperAdminHeader />}
      <Body />
      {menu}
    </>
  );
}
