import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import MembershipModel from "src/models/MembershipModel";
import calculateCouponsForRegistration from "src/utils/calculateCouponsForRegistration";
import { calculatePricingForMembership } from "src/utils/calculatePricingForMembership";
import { coupons } from "types/code-generator";
import { v4 as uuid } from "uuid";

interface GetInvoiceItemForMembershipInput {
  membership: MembershipModel;
  startDate: Date;
  coupons: coupons["coupons"];
}

export function getInvoiceItemForMembership({
  membership,
  startDate,
  coupons,
}: GetInvoiceItemForMembershipInput) {
  const { proratedPrice } = calculatePricingForMembership({
    membership,
    startDate,
  });

  // console.log("price", price);

  const registrationCoupons = calculateCouponsForRegistration({
    coupons,
    registration: membership,
    startDate,
  });

  const description = `${
    membership.offering?.program?.title
  }: ${membership.roster
    .map((r) => `${r.firstName} ${r.lastName}`)
    .join(", ")}`;

  const invoiceItem = new InvoiceItemModel({
    id: uuid(),
    amount: proratedPrice,
    description,
    membership,
  });

  // console.log("registrationCoupons", registrationCoupons);

  invoiceItem.coupons = registrationCoupons;

  return invoiceItem;
}
