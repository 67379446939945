import { darken } from "polished";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useStore } from "src/stores/Store";
import colors from "src/utils/colors";
import tw from "src/utils/tw";

interface Props {
  className?: string;
  children: ReactNode;
  onClick?: (e) => void;
  disabled?: boolean;
  round?: boolean;
  type?: "reset" | "submit" | "button";
  small?: boolean;
  xSmall?: boolean;
  dashed?: boolean;
  bgColor?: string;
  borderColor?: string;
  id?: string;
  isPreview?: boolean;
  textColor?: string;
  to?: string;
  href?: string;
  target?: string;
}

export function BorderButton(props: Props) {
  const store = useStore();

  let backgroundColor = colors.bgGray;
  let backgroundActiveColor = colors.borderGray;
  let borderColor = colors.borderGray;
  let borderActiveColor = colors.darkGray;
  let color = colors.dark;

  if (!store) {
    backgroundColor = colors.main;
    backgroundActiveColor = colors.mainDark;
    borderColor = colors.mainDark;
    borderActiveColor = colors.mainDarker;
  }

  if ((store?.isAdminArea || store?.isSuperAdminArea) && !props.isPreview) {
    if (props.type === "submit") {
      backgroundColor = colors.main;
      backgroundActiveColor = colors.mainDark;
      borderColor = colors.mainDark;
      borderActiveColor = colors.mainDarker;
    }
  } else if (store?.theme) {
    color = store?.theme.orgOrgColor;

    if (props.type === "submit") {
      backgroundColor = store?.theme.orgOrgColor;
      backgroundActiveColor = darken(0.05, store?.theme.orgOrgColor);
      borderColor = darken(0.05, store?.theme.orgOrgColor);
      borderActiveColor = darken(0.1, store?.theme.orgOrgColor);
      color = "#fff";
    }
  }

  if (props.bgColor) {
    backgroundColor = props.bgColor;
    backgroundActiveColor = darken(0.05, props.bgColor);
  }

  if (props.borderColor) {
    borderColor = props.borderColor;
    borderActiveColor = darken(0.1, props.borderColor);
  }

  if (props.textColor) {
    color = props.textColor;
  }

  if (props.className?.includes("border-organization")) {
    borderColor = undefined;
  }

  if (props.className?.includes("text-")) {
    color = undefined;
  }

  const classes = tw(
    props.dashed ? "border-dashed border-b" : "border-b-3",
    props.dashed && !props.disabled && "hover:border-empty",
    !props.dashed && !props.disabled && "active:border-b active:border-t-3",
    "align-bottom",
    "border-t border-l border-r",
    "rounded-full",
    !props.round && !props.xSmall && "px-5",
    !props.round && props.xSmall && "px-3",
    "font-bold",
    "transition-all",
    props.small ? "h-9" : "h-12",
    props.xSmall && "h-8",
    !props.round && "truncate",
    props.disabled && "opacity-60",
    props.round ? (props.small ? "w-9" : props.xSmall ? "w-8" : "w-12") : ""
  );

  if (props.to) {
    return (
      <Link
        to={props.disabled ? undefined : props.to}
        css={{
          backgroundColor,
          borderColor,
          color,
          "&:active": {
            backgroundColor: backgroundActiveColor,
            borderColor: borderActiveColor,
          },
        }}
        className={tw(
          classes,
          "border-borderGray",
          "text-dark",
          "inline-block",
          "cursor-pointer",
          "active:border-b active:border-t-3",
          "text-center",
          "inline-flex items-center justify-center gap-2",
          props.className
        )}
        onClick={props.disabled ? undefined : props.onClick}
        id={props.id}
      >
        {props.children}
      </Link>
    );
  }

  if (props.href) {
    return (
      <a
        href={props.href}
        css={{
          backgroundColor,
          borderColor,
          color,
          "&:active": {
            backgroundColor: backgroundActiveColor,
            borderColor: borderActiveColor,
          },
        }}
        className={tw(
          classes,
          "border-borderGray",
          "text-dark",
          "inline-block",
          "cursor-pointer",
          "active:border-b active:border-t-3",
          "text-center",
          "inline-flex items-center justify-center",
          props.className
        )}
        target={props.target}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      onClick={props.disabled ? undefined : props.onClick}
      css={{
        backgroundColor,
        borderColor,
        color,
        "&:active": !props.disabled
          ? {
              backgroundColor: backgroundActiveColor,
              borderColor: borderActiveColor,
            }
          : undefined,
      }}
      id={props.id}
      className={tw(
        classes,
        !props.disabled && (props.onClick || props.type === "submit")
          ? "cursor-pointer"
          : "cursor-default",
        props.className
      )}
      disabled={props.disabled}
      type={props.type || "button"}
      data-test={props["data-test"]}
    >
      {props.children}
    </button>
  );
}
