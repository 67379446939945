import { gql } from "graphql-tag";
import { InvoiceItemPayload } from "src/api/InvoiceItem";
import { PrivateSerieAttendancePayload } from "src/api/PrivateSerieAttendance";
import { withApi, withApiMutation } from "src/stores/Api";
import { getSearchParams } from "src/utils/useSearchParams";
import {
  PrivateSerieStatus,
  createPrivateSerie,
  createPrivateSerieVariables,
  filteredPrivateSeries,
  filteredPrivateSeriesForUser,
  filteredPrivateSeriesForUserCounts,
  filteredPrivateSeriesForUserCountsVariables,
  filteredPrivateSeriesForUserVariables,
  filteredPrivateSeriesVariables,
  privateSerie,
  privateSerieVariables,
  privateSeriesForUser,
  privateSeriesForUserVariables,
  updatePrivateSerie,
  updatePrivateSerieVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

const PrivateSeriePayloadFragment = gql`
  fragment PrivateSeriePayload on PrivateSerie {
    accountOwner {
      id
      address1
      credits {
        id
        amount
      }
      firstName
      lastName
      email
      phone
      stripeCustomerId
      stripeToken
    }
    attendances {
      ...PrivateSerieAttendancePayload
    }
    availableDays
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    customerInfoRequestResponses {
      id
      answer
      question
      user {
        id
      }
    }
    events {
      id
      createdAt
      date
      type
      privateSerieAttendanceId
      by {
        id
        email
        firstName
        lastName
        level
      }
      to {
        id
        email
        firstName
        lastName
      }
      value
    }
    firstLessonEnd
    firstLessonStart
    id
    instructor {
      id
      email
      firstName
      lastName
    }
    instructorsToNotify {
      id
      email
      firstName
      lastName
    }
    invoiceItems {
      ...InvoiceItemPayload
      invoice {
        id
        createdAt
        invoiceDate
        invoiceItems {
          ...InvoiceItemPayload
        }
        status
      }
    }
    note
    numberOfLessons
    offering {
      id
      additionalFeeAmount
      addons {
        id
        title
        description
        billingCategory {
          id
          title
        }
        price
      }
      agreements {
        id
        title
        description
        required
      }
      allowAdditionalStudents
      allowExtension
      applyAnnualFee
      atAccountHome
      availableDays
      billingCategory {
        id
        title
      }
      customerInfoRequests
      classTimes {
        id
        atAccountHome
        date
        day
        endTime
        location {
          id
          address1
          city
          state
          title
          zip
        }
        startTime
        zone {
          id
          title
        }
      }
      collectTaxes
      communications {
        id
        ruleAmount
        ruleAmountType
      }
      coupons {
        id
        amount
        availableForAllOfferings
        code
        expirationDate
        frequency
        title
        amountType
      }
      customerInfoRequests
      department {
        id
        active
        annualFeeAmount
        annualFeeMax
        annualFeeType
        contactName
        default
        email
        filters
        phone
        tags {
          id
          order
          title
        }
        title
      }
      description
      discounts {
        id
        amount
        max
        min
      }
      duration
      endDate
      excludedDates
      extensionDiscounts {
        id
        amount
        max
        min
      }
      extensionPrice
      extensionPriceAdjustments {
        id
        min
        price
      }
      extensionMaxAttendances
      extensionMinAttendances
      fees {
        id
        amount
        title
      }
      firstLessonStartDays
      firstLessonStart
      frequency
      hideDefaultStaff
      instructor {
        id
        email
        firstName
        lastName
      }
      location {
        id
        address1
        title
      }
      maxAge
      maxAgeType
      minAge
      minAgeType
      minAttendances
      multiClassDiscount
      multiStudentDiscount
      package
      participantType
      paymentFrequency
      price
      priceAdjustments {
        id
        min
        price
      }
      priceForAdditionalStudents
      program {
        id
        description
        title
      }
      publishDate
      publishOnWebsite
      registrationClose
      registrationOpen
      requestStartDate
      restrictReschedule
      restrictRescheduleType
      startDate
      status
      studentInfoRequests {
        id
        answers
        description
        noQuestion
        required
        slug
        type
        yesQuestion
      }
      tags {
        id
        title
      }
      title
      type
      unpublishDate
    }
    organization {
      id
      title
    }
    previousSerie {
      id
      createdAt
      events {
        id
        createdAt
        type
        by {
          id
          email
          firstName
          lastName
        }
        to {
          id
          email
          firstName
          lastName
        }
        value
      }
      invoiceItems {
        ...InvoiceItemPayload
      }
    }
    roster {
      id
      avatar {
        url
      }
      firstName
      lastName
      gender
      birthDate
    }
    scheduledThrough
    status
    studentInfoRequestResponses {
      id
      description
      additionalResponse
      studentInfoRequest {
        id
        description
      }
    }
    updatedAt
  }
  ${PrivateSerieAttendancePayload}
  ${InvoiceItemPayload}
`;

const PrivateSerieListPayloadFragment = gql`
  fragment PrivateSerieListPayload on PrivateSerie {
    accountOwner {
      id
    }
    attendances {
      id
      date
      status
      location {
        id
      }
    }
    createdAt
    events {
      id
      createdAt
      type
      by {
        id
        email
        firstName
        lastName
      }
      to {
        id
        email
        firstName
        lastName
      }
      value
    }
    id
    instructor {
      id
      firstName
      lastName
    }
    numberOfLessons
    offering {
      id
      department {
        id
        title
      }
      duration
      participantType
      price
      type
    }
    roster {
      id
      birthDate
      firstName
      gender
      lastName
    }
    status
    updatedAt
  }
`;

export const UpdatePrivateSerieMutation = gql`
  mutation updatePrivateSerie(
    $data: PrivateSerieCreateInput!
    $where: UniqueInput!
  ) {
    updatePrivateSerie(data: $data, where: $where) {
      ...PrivateSeriePayload
    }
  }
  ${PrivateSeriePayloadFragment}
` as TypedDocumentNode<updatePrivateSerie, updatePrivateSerieVariables>;

const DeletePrivateSerieMutation = gql`
  mutation deletePrivateSerie($id: String!) {
    deletePrivateSerie(where: { id: $id }) {
      id
    }
  }
`;

export const CreatePrivateSerieMutation = gql`
  mutation createPrivateSerie($data: PrivateSerieCreateInput!) {
    createPrivateSerie(data: $data) {
      ...PrivateSeriePayload
    }
  }
  ${PrivateSeriePayloadFragment}
` as TypedDocumentNode<createPrivateSerie, createPrivateSerieVariables>;

export const PrivateSeriesStatusQuery = gql`
  query privateSeriesStatus($organizationId: String!) {
    privateSeries(where: { organizationId: $organizationId }) {
      id
      status
    }
  }
`;

export const FilteredPrivateSeriesQuery = gql`
  query filteredPrivateSeries(
    $where: PrivateSerieWhereInput!
    $filter: String
    $skip: Int
    $take: Int
  ) {
    privateSeries(where: $where, filter: $filter, take: $take, skip: $skip) {
      ...PrivateSerieListPayload
    }
  }
  ${PrivateSerieListPayloadFragment}
` as TypedDocumentNode<filteredPrivateSeries, filteredPrivateSeriesVariables>;

const PrivateSeriesForInstructorQuery = gql`
  query privateSeriesForInstructorQuery(
    $organizationId: String!
    $instructorId: String!
  ) {
    privateSeries(
      where: {
        organizationId: $organizationId
        instructorId: $instructorId
        status: { notIn: [Archived] }
      }
    ) {
      ...PrivateSeriePayload
    }
  }
  ${PrivateSeriePayloadFragment}
`;

export const PrivateSeriesForUserQuery = gql`
  query privateSeriesForUser(
    $where: PrivateSerieWhereInput!
    $skip: Int
    $take: Int
  ) {
    privateSeries(where: $where, take: $take, skip: $skip) {
      ...PrivateSeriePayload
    }
  }
  ${PrivateSeriePayloadFragment}
` as TypedDocumentNode<privateSeriesForUser, privateSeriesForUserVariables>;

export const FilteredPrivateSeriesForUserQuery = gql`
  query filteredPrivateSeriesForUser(
    $where: PrivateSerieWhereInput!
    $filter: String
    $skip: Int
    $take: Int
  ) {
    privateSeries(where: $where, filter: $filter, take: $take, skip: $skip) {
      ...PrivateSeriePayload
    }
  }
  ${PrivateSeriePayloadFragment}
` as TypedDocumentNode<
  filteredPrivateSeriesForUser,
  filteredPrivateSeriesForUserVariables
>;

export const PrivateSerieQuery = gql`
  query privateSerie($id: String!) {
    privateSerie(where: { id: $id }) {
      ...PrivateSeriePayload
    }
  }
  ${PrivateSeriePayloadFragment}
` as TypedDocumentNode<privateSerie, privateSerieVariables>;

const UpcomingPrivateSeriesQuery = gql`
  query upcomingPrivateSeries($accountOwnerId: String!) {
    privateSeries(
      where: {
        OR: [
          { accountOwnerId: $accountOwnerId }
          { accountOwner: { profileId: $accountOwnerId } }
        ]
        status: { equals: InProgress }
      }
    ) {
      ...PrivateSeriePayload
    }
  }
  ${PrivateSeriePayloadFragment}
`;

const FilteredPrivateSeriesCountsQuery = gql`
  query filteredPrivateSeriesCounts(
    $where: PrivateSerieWhereInput!
    $filter: String
  ) {
    privateSeriesCounts(where: $where, filter: $filter) {
      total
      staffAssigned
      participants
      actionNeeded
    }
  }
`;

export const FilteredPrivateSeriesCountsForUserQuery = gql`
  query filteredPrivateSeriesForUserCounts(
    $where: PrivateSerieWhereInput!
    $filter: String
  ) {
    privateSeriesCounts(where: $where, filter: $filter) {
      count
    }
  }
` as TypedDocumentNode<
  filteredPrivateSeriesForUserCounts,
  filteredPrivateSeriesForUserCountsVariables
>;

export const QueryFilteredPrivateSeriesCounts = withApi(
  FilteredPrivateSeriesCountsQuery,
  {
    name: "queryFilteredPrivateSeriesCounts",
    options: ({ store, location }: any) => {
      const countParams = getSearchParams(["sort", "order"]);

      return {
        variables: {
          where: { organizationId: store.organization.id },
          filter: countParams.toString(),
        },
      };
    },
  }
);

export const QueryUpcomingPrivateSeries = withApi(UpcomingPrivateSeriesQuery, {
  name: "queryUpcomingPrivateSeries",
  options: ({ store }: any) => {
    const { user } = store;
    return {
      variables: {
        accountOwnerId: user.id,
      },
    };
  },
});

export const UpdatePrivateSerie = withApiMutation(UpdatePrivateSerieMutation, {
  name: "updatePrivateSerie",
});

export const QueryFilteredPrivateSeries = withApi(FilteredPrivateSeriesQuery, {
  name: "queryFilteredPrivateSeries",
  skip: ({ match }) => {
    if (
      match.params.tab &&
      (match.params.tab === "offerings" || match.params.tab === "sessions")
    ) {
      return true;
    }

    return false;
  },
  options: ({ store, location, match }: any): any => {
    const params = new URLSearchParams(location.search);
    const statuses = params.getAll("status");
    const paramFilter = params.get("filter");
    const currentTab =
      match.params && match.params.tab ? match.params.tab : "pending";

    let status: PrivateSerieStatus[] = [
      PrivateSerieStatus.Pending,
      PrivateSerieStatus.Posted,
    ];

    if (store.organization.solo) {
      status = [PrivateSerieStatus.InProgress];
    }

    if (statuses.includes("active")) {
      status.push(PrivateSerieStatus.InProgress);
    }

    if (paramFilter === "in-progress" || currentTab === "active") {
      status = [PrivateSerieStatus.InProgress];
    } else if (paramFilter === "abandoned" || currentTab === "abandoned") {
      status = [PrivateSerieStatus.Abandoned];
    } else if (paramFilter === "completed" || currentTab === "closed") {
      status = [PrivateSerieStatus.Completed];
    } else if (paramFilter === "all") {
      return {
        variables: {
          organizationId: store.organization.id,
        },
      };
    }

    return {
      variables: {
        where: {
          organizationId: store.organization.id,
          status: {
            in: status,
          },
        },
      },
    };
  },
});

export const QueryPrivateSeriesForInstructor = withApi(
  PrivateSeriesForInstructorQuery,
  {
    name: "queryPrivateSeriesForInstructor",
    options: ({ store, match }: any) => {
      let instructorId;

      if (match && match.params.id && store.user.isAdminOrAbove) {
        instructorId = match.params.id;
      } else {
        instructorId = store.user.id;
      }

      return {
        variables: {
          organizationId: store.organization.id,
          instructorId,
        },
      };
    },
  }
);

export const DeletePrivateSerie = withApiMutation(DeletePrivateSerieMutation, {
  name: "deletePrivateSerie",
});

export const QueryPrivateSerie = withApi(PrivateSerieQuery, {
  skip: ({ match }: any) => !match.params.id,
  options: ({ match }: any) => ({
    variables: { id: match.params.id },
  }),
  name: "queryPrivateSerie",
});
