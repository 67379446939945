import { useModal } from "@ebay/nice-modal-react";
import { gql } from "graphql-tag";
import { groupBy } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { UserQuery } from "src/api/User";
import UserAvatar from "src/components/layout/UserAvatar";
import { LevelTag } from "src/components/user/LevelTag";
import UserModel from "src/models/UserModel";
import { apiClient, useInfiniteApi } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import message from "src/utils/message";
import { modals } from "src/utils/modals";
import { useDebounce } from "src/utils/useDebounce";
import { useFakeInput } from "src/utils/useFakeInput";
import {
  Level,
  SortOrder,
  filteredUsersVariables,
  modalUsers,
  modalUsersVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";
import { InputText } from "../inputs/InputText";
import StudentAge from "../items/StudentAge";
import List from "../layout/List";
import Loading from "../layout/Loading";
import Modal from "./Modal";

interface Props {
  levels?: Level[];
  handleOk: (userProfile: UserModel) => void;
  helpText?: string;
  user?: UserModel;
  title?: string;
  idNot?: string[];
  primaryOnly?: boolean;
}

export const FilterUsersModal = modals.create((props: Props) => {
  const [value, setValue] = useState("");
  const inputRef = useRef(null);
  const scrollRef = useRef(null);
  const store = useStore();
  const debouncedValue = useDebounce(value, { wait: 1000 });
  const modal = useModal();
  const fakeInput = useFakeInput();

  const variables: filteredUsersVariables = {
    where: {
      level: {
        in: props.levels,
      },
      id: props.idNot
        ? {
            notIn: props.idNot,
          }
        : undefined,
    },
    skip: 0,
    take: 10,
    orderBy: {
      lastName: SortOrder.asc,
    },
    filter: debouncedValue ? `?search=${debouncedValue}` : "",
  };

  if (!store.isSuperAdminArea) {
    variables.where.organizationId = store.organization.id;
  }

  if (props.user) {
    variables.where.id = {
      not: props.user.id,
    };
  }

  if (props.primaryOnly) {
    variables.where.primaryAccountId = {
      equals: null,
    };
  }

  const getUserData = useInfiniteApi(ModalUsersQuery, {
    variables,
  });

  const users = getUserData?.users || [];

  const groupedUsers = groupBy(users, (user) => {
    if (user.primaryAccount) {
      return user.primaryAccount.id;
    }

    if (user.accountOwner) {
      return user.accountOwner.id;
    }

    return user.id;
  });

  for (const key in groupedUsers) {
    const group = groupedUsers[key];

    const sortedGroup = group.sort((a, b) => {
      if (a.level !== Level.Student && !a.primaryAccount) {
        return -1;
      }

      if (a.level === Level.Student && b.level === Level.Student) {
        return a.firstName.localeCompare(b.firstName);
      }

      return 0;
    });

    groupedUsers[key] = sortedGroup;
  }

  const sortedUsers = Object.values(groupedUsers).flat();

  useEffect(() => {
    if (modal.visible && fakeInput && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
      fakeInput.remove();
    }
  }, [modal.visible, inputRef.current]);

  function handleChange(e) {
    const newValue = e;

    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0 });
    }

    if (newValue !== value) {
      setValue(newValue);
    }
  }

  function onEndReached() {
    if (!getUserData) {
      return;
    }
    getUserData.fetchNextPage();
  }

  async function clearValue() {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0 });
    }

    if (inputRef.current?.input) {
      inputRef.current.input.focus();
    }

    setValue(null);
  }

  async function selectUser(user) {
    const processingMessage: any = message.loading("Processing…");

    const userData = await apiClient(UserQuery, {
      variables: {
        id: user.id,
      },
    }).then((res) => new UserModel(res.user));

    processingMessage();

    props.handleOk(userData);
    modal.hide();
  }

  function renderUser(item, index) {
    const user = new UserModel(item);
    const name = `${user.firstName} ${user.lastName}`;
    let students = user.students;

    if (user.primaryAccount) {
      students = user.primaryAccount.students;
    }

    return (
      <div
        key={index}
        className="flex cursor-pointer gap-4 border-b border-borderGray px-4 py-3 transition hover:bg-bgGray"
        onClick={() => selectUser(user)}
      >
        {user.level === Level.Student && (
          <div>
            <UserAvatar user={user} small showInitials />
          </div>
        )}

        <div className="flex-1">
          <LevelTag user={user} />

          {user.accountOwner && (
            <div className="step-subhead">
              {user.accountOwner.lastName} Account
            </div>
          )}

          <div
            className="leading-tight text-organization"
            dangerouslySetInnerHTML={{
              __html: name.replace(
                new RegExp(debouncedValue, "gi"),
                (match) => `<strong>${match}</strong>`
              ),
            }}
          />

          {user.email && (
            <div
              className="text-sm"
              dangerouslySetInnerHTML={{
                __html: user.email.replace(
                  new RegExp(debouncedValue, "gi"),
                  (match) => `<strong>${match}</strong>`
                ),
              }}
            />
          )}

          {students.length > 0 && (
            <div className="flex items-center gap-1.5 text-sm">
              <div>
                <i className="far fa-user text-xs" />
              </div>
              <div className="flex-1">
                {students.map((s) => `${s.firstName} ${s.lastName}`).join(", ")}
              </div>
            </div>
          )}

          <div className="text-sm">
            {user.birthDate && <StudentAge student={user} />}{" "}
            {user.birthDate && user.gender && " • "}{" "}
            {user.gender && user.gender}
          </div>
        </div>
      </div>
    );
  }

  function renderUsers() {
    return (
      <div
        css={{
          height: props.helpText
            ? `calc(100vh - 209px)`
            : `calc(100vh - 189px)`,
        }}
      >
        <List
          rows={sortedUsers}
          renderRow={renderUser}
          onEndReached={onEndReached}
          scrollOffset={100}
          loading={getUserData.loading}
          emptyMessage={"No users found matching that term."}
        />
      </div>
    );
  }

  return (
    <Modal
      visible={modal.visible}
      handleCancel={modal.hide}
      afterClose={modal.remove}
      title={props.title || "Select User"}
      // initialFocus={inputRef}
    >
      <div className="-mx-4 -my-4">
        <div className="px-4 pt-4" css={{ height: props.helpText ? 90 : 60 }}>
          {props.helpText && <p className="mb-3">{props.helpText}</p>}
          <div className="relative">
            <InputText
              placeholder="Search"
              id="search"
              value={value}
              autoFocus
              onChange={handleChange}
              ref={inputRef}
              className="rounded-full"
            />
            {(getUserData.loading || value) && (
              <div
                className="flex items-center"
                css={{
                  position: "absolute",
                  right: "1rem",
                  top: 0,
                  bottom: 0,
                }}
              >
                {getUserData.loading ? (
                  <div>
                    <Loading small css={{ padding: 0 }} />
                  </div>
                ) : value ? (
                  <div
                    className="cursor-pointer text-empty"
                    onClick={clearValue}
                  >
                    <i aria-hidden className="far fa-circle-xmark" />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <hr className="mb-0" />
        {renderUsers()}
      </div>
    </Modal>
  );
});

export const ModalUsersQuery = gql`
  query modalUsers(
    $where: UserWhereInput!
    $orderBy: UserOrderByInput
    $filter: String
    $skip: Int
    $take: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      filter: $filter
      take: $take
      skip: $skip
    ) {
      id
      accountOwner {
        id
        email
        firstName
        lastName
        phone
        linkedAccounts {
          id
          firstName
          lastName
        }
      }
      avatar {
        id
        url
      }
      phone
      primaryAccount {
        id
        students {
          id
          firstName
          lastName
        }
      }
      email
      firstName
      lastName
      birthDate
      level
      gender
      stripeCustomerId
      stripeToken
      students {
        id
        firstName
        lastName
      }
    }
  }
` as TypedDocumentNode<modalUsers, modalUsersVariables>;
