import moment from "moment-timezone";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import UserModel from "src/models/UserModel";
import {
  Day,
  Event,
  NotificationType,
  PrivateSerieAttendanceStatus,
  PrivateSerieStatus,
} from "types/code-generator";
import CustomerInfoRequestModel from "./CustomerInfoRequestResponseModel";
import { CustomerServiceItemModel } from "./CustomerServiceItemModel";
import OfferingModel from "./OfferingModel";
import OrganizationModel from "./OrganizationModel";
import PrivateSerieAttendanceModel from "./PrivateSerieAttendanceModel";
import StudentInfoRequestResponseModel from "./StudentInfoRequestResponseModel";

export const isPrivateSerie = (
  registration
): registration is PrivateSerieModel => {
  return (
    registration instanceof PrivateSerieModel ||
    registration.numberOfLessons !== undefined
  );
};

export default class PrivateSerieModel {
  accountEmail: string;
  accountNotificationType: NotificationType = NotificationType.Email;
  accountOwner: UserModel;
  accountPhone: string;
  attendances: PrivateSerieAttendanceModel[] = [];
  availableDays: Day[] = [];
  createdAt: Date;
  createdBy: UserModel;
  customerInfoRequestResponses: CustomerInfoRequestModel[] = [];
  customerServiceItems: CustomerServiceItemModel[] = [];
  events: Event[];
  firstLessonEnd: Date;
  firstLessonStart: Date;
  id: string;
  initialNumberOfLessons: number = 0;
  instructor: UserModel;
  instructorsToNotify: UserModel[] = [];
  invoiceItems: InvoiceItemModel[];
  note: string = "";
  numberOfLessons: number = 0;
  offering: OfferingModel;
  organization: OrganizationModel;
  previousSerie: PrivateSerieModel;
  roster: UserModel[] = [];
  scheduledThrough: Date;
  specialRequests: string = "";
  status: PrivateSerieStatus;
  studentInfoRequestResponses: StudentInfoRequestResponseModel[] = [];
  unread: boolean = true;
  updatedAt: Date;

  // Local only
  isExtending: boolean = false;
  isNew: boolean = false;

  constructor(data: PrivateSerieModel | any) {
    Object.assign(this, data);

    if (data) {
      this.offering = new OfferingModel(data.offering || {});

      if (data.attendances) {
        this.attendances = data.attendances.map(
          (a) => new PrivateSerieAttendanceModel(a)
        );
      }
    }
  }

  getScheduledLessons() {
    return this.attendances
      ? this.attendances.filter(
          (a) =>
            a.status === PrivateSerieAttendanceStatus.Scheduled ||
            a.status === PrivateSerieAttendanceStatus.Reschedule
        ).length
      : 0;
  }

  getCompletedLessons() {
    return this.attendances
      ? this.attendances.filter(
          (a) => a.status === PrivateSerieAttendanceStatus.Completed
        ).length
      : 0;
  }

  getRemainingLessons() {
    return this.attendances
      ? this.attendances.length -
          this.getScheduledLessons() -
          this.getCompletedLessons() -
          this.getVerifiedLessons()
      : 0;
  }

  getVerifiedLessons() {
    return this.attendances
      ? this.attendances.filter(
          (a) => a.status === PrivateSerieAttendanceStatus.Verified
        ).length
      : 0;
  }

  getAvailableAutomatedLessons(date = new Date()) {
    const today = moment(date);
    const nextMonth = today.clone().add(1, "month").startOf("month");

    const createdAttendances = this.attendances.filter((a) => {
      return moment(a.createdAt).isSame(nextMonth, "month") && a.automated;
    });

    return createdAttendances;
  }

  getNotScheduled() {
    return this.attendances
      ? this.attendances.filter(
          (a) =>
            a.status === PrivateSerieAttendanceStatus.Pending ||
            a.status === PrivateSerieAttendanceStatus.Canceled
        ).length
      : 0;
  }
}
