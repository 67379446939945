import moment from "moment-timezone";
import MembershipModel from "src/models/MembershipModel";
import { MembershipOfferingTypes } from "src/utils/constants";
import { Frequency } from "types/code-generator";

interface CalculatePricingForMembership {
  membership: MembershipModel;
  startDate: Date;
}

export function calculatePricingForMembership(
  args: CalculatePricingForMembership
) {
  if (!args.membership) {
    return {
      price: 0,
      proratedPrice: 0,
    };
  }

  let price = args.membership.offering.price || 0;
  let today = moment();
  const offeringStartDate =
    args.membership.offering.startDate &&
    moment(args.membership.offering.startDate);

  if (args.startDate) {
    today = moment(args.startDate);
  }

  if (args.membership.offering.priceLevels?.length > 0) {
    const members = args.membership.roster.length;

    for (const p of args.membership.offering.priceLevels) {
      if (p.number === members) {
        price = p.price;
      }
    }
  }

  let proratedPrice = price;

  if (
    args.membership.offering.prorate &&
    args.membership.offering.type !== MembershipOfferingTypes["Limited Pass"]
  ) {
    let date = today.clone().startOf("day");
    let endDate = date.clone().endOf("month");
    let totalNumberOfDays = date.daysInMonth();

    if (args.membership.offering.paymentFrequency === Frequency.Annual) {
      totalNumberOfDays = 365;
      endDate = date.clone().add(1, "year");

      if (args.membership.offering.recurringDate) {
        const chargeDate = moment(args.membership.offering.recurringDate).set({
          year: date.year(),
        });

        if (chargeDate.isAfter(date, "day")) {
          endDate = chargeDate;
        }

        if (chargeDate.isBefore(date, "day")) {
          endDate = chargeDate.clone().add(1, "year");
        }
      }
    }

    if (args.membership.offering.endDate) {
      endDate = moment(args.membership.offering.endDate);
    }

    let remainingNumberOfDays = Math.abs(date.diff(endDate, "day")) + 1;

    if (
      (offeringStartDate && offeringStartDate.isBefore(today, "day")) ||
      !offeringStartDate
    ) {
      // console.log("totalNumberOfDays", totalNumberOfDays);
      // console.log("startDate", date.format());
      // console.log("endDate", endDate.format());
      // console.log("remainingNumberOfDays", remainingNumberOfDays);

      let pricePerDay = price / totalNumberOfDays;

      if (remainingNumberOfDays < totalNumberOfDays) {
        proratedPrice = pricePerDay * remainingNumberOfDays;
      }
    }
  }

  if (
    offeringStartDate &&
    offeringStartDate.isAfter(today, "month") &&
    args.membership.offering.paymentFrequency !== Frequency.OneTime
  ) {
    price = 0;
    proratedPrice = 0;
  }

  return {
    price: Math.ceil(price),
    proratedPrice: Math.ceil(proratedPrice),
  };
}
