import ClassModel from "src/models/ClassModel";
import OrganizationModel from "src/models/OrganizationModel";
import UserModel from "src/models/UserModel";
import { coupons, Event, GroupRegistrationStatus } from "types/code-generator";
import AddonModel from "./AddonModel";
import CustomerInfoRequestResponseModel from "./CustomerInfoRequestResponseModel";
import CustomerRequestModel from "./CustomerRequestModel";
import DiscountModel from "./DiscountModel";
import { InvoiceItemModel } from "./InvoiceItemModel";
import PaymentModel from "./PaymentModel";
import StudentInfoRequestResponseModel from "./StudentInfoRequestResponseModel";

export const isGroupRegistration = (
  registration
): registration is GroupRegistrationModel => {
  return (
    registration instanceof GroupRegistrationModel ||
    ((registration.student !== undefined ||
      registration.roster !== undefined) &&
      registration.class !== undefined)
  );
};

export default class GroupRegistrationModel {
  accountOwner: UserModel;
  addons: AddonModel[];
  class: ClassModel;
  coupons: coupons["coupons"];
  createdAt: Date;
  createdBy: UserModel;
  customerInfoRequestResponses: CustomerInfoRequestResponseModel[] = [];
  customerRequest: CustomerRequestModel;
  dateApproved: Date;
  discounts: DiscountModel[];
  events: Event[] = [];
  finalDate: Date;
  id: string;
  invoiceItems: InvoiceItemModel[];
  note: string;
  organization: OrganizationModel;
  reason: string;
  roster?: UserModel[];
  status: GroupRegistrationStatus;
  student?: UserModel;
  studentId?: string;
  studentInfoRequestResponses: StudentInfoRequestResponseModel[] = [];
  transactions: PaymentModel[];
  unread: boolean = true;
  updatedAt: Date;

  // Session keys
  approvedWaitlist?: boolean;
  error?: string;

  constructor(data: GroupRegistrationModel | any = {}) {
    Object.assign(this, data);

    if (data?.class) {
      if (data.class.active !== undefined) {
        delete data.class.active;
      }

      this.class = new ClassModel(data.class);
    }
  }
}
