import { useHistory, useLocation } from "react-router-dom";
import { FilteredPrivateSeriesCountsForUserQuery } from "src/api/PrivateSerie";
import { SuperAdminBar } from "src/components/header/SuperAdminHeader";
import Container from "src/components/layout/Container";
import UserModel from "src/models/UserModel";
import { useApi } from "src/stores/Api";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import { useUI } from "src/stores/UI";
import {
  SwimAmericaDomains,
  hideHeader,
  swimAmericaHideHeader,
} from "src/utils/constants";
import { modals } from "src/utils/modals";
import tw from "src/utils/tw";
import { IntegrationType, Level } from "types/code-generator";
import { BorderButton } from "../buttons/BorderButton";
import { FilterUsersModal } from "../modals/FilterUsersModal";
import HeaderButton from "./HeaderButton";
import HeaderCartButton from "./HeaderCartButton";
import HeaderLogo from "./HeaderLogo";
import HeaderNavButton from "./HeaderNavButton";
import ViewingAsHeader from "./ViewingAsHeader";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export function Header(props: Props) {
  const ui = useUI();
  const history = useHistory();
  const store = useStore();
  const session = useSession();
  const location = useLocation();

  const privateSeriesCountsQuery = useApi(
    FilteredPrivateSeriesCountsForUserQuery,
    {
      variables: {
        where: {
          organizationId: store.organization?.id,
          accountOwnerId: store.user?.id,
        },
      },
      skip: !store.user?.isStaff,
    }
  );

  const privateSerieCounts = privateSeriesCountsQuery.privateSeriesCounts;

  function toggleMenu() {
    ui.toggleMenu();
  }

  function searchForUsers() {
    modals.show(FilterUsersModal, {
      levels: [
        Level.Student,
        Level.Admin,
        Level.Instructor,
        Level.AccountOwner,
      ],
      handleOk: (user) => {
        selectUser(user);
      },
    });
  }

  function selectUser(userProfile: UserModel) {
    if (userProfile) {
      if (userProfile.primaryAccount) {
        history.push(`/admin/users/${userProfile.primaryAccount.id}`);
      } else {
        history.push(`/admin/users/${userProfile.id}`);
      }
    }
  }

  let headerLogo = <>{store.organization?.title}</>;
  let headerLink = "/";
  const myLessonsNumber = privateSerieCounts?.count || 0;

  if (store.isApp) {
    headerLogo = (
      <img src="/img/captyn-logo.svg" alt="Captyn" width="91" height="16" />
    );
  }

  if (store.user) {
    if (store.user.isAdminOrAbove) {
      if (store.organization.integrations.includes(IntegrationType.FleetFeet)) {
        headerLogo = (
          <img
            src="/img/fleetfeet-header.jpg"
            alt="FleetFeet"
            width="120"
            height="24"
          />
        );
      } else {
        headerLogo = (
          <img src="/img/captyn-logo.svg" alt="Captyn" width="91" height="16" />
        );
      }

      headerLink = "/admin";

      if (store.user.isSalesOrAbove && store.isApp) {
        headerLink = "/super-admin";
      }
    } else if (store.user.isInstructor) {
      headerLogo = (
        <img src="/img/captyn-logo.svg" alt="Captyn" width="91" height="16" />
      );
      headerLink = "/account";
    } else if (store.user.level === Level.AccountOwner) {
      headerLink = "/account";
    }
  }

  let headerButtons = null;
  const headerNumber = store.unread || 0;

  if (
    store.user &&
    store.user.isStaff &&
    store.isAdminArea &&
    !store.isSuperAdminArea
  ) {
    headerButtons = (
      <div>
        <HeaderButton onClick={searchForUsers}>
          <i aria-hidden className="far fa-magnifying-glass" />
        </HeaderButton>
        <HeaderButton
          to="/admin/pending"
          type="dark"
          isEmpty={headerNumber === 0}
        >
          {store.user.isInstructor ? myLessonsNumber : headerNumber}
        </HeaderButton>
      </div>
    );
  } else if (!store.user) {
    headerButtons = (
      <div>
        <BorderButton
          onClick={() => history.push("/login")}
          type="submit"
          small
        >
          Sign In
        </BorderButton>
      </div>
    );
  } else if (store.isSuperAdminArea) {
    headerButtons = (
      <div>
        <HeaderButton to="/super-admin/organizations/add" className="align-bo">
          <i aria-hidden className="far fa-plus relative top-px" />
        </HeaderButton>
      </div>
    );
  } else if (store.user) {
    headerButtons = (
      <div>
        <HeaderButton to="/account/settings" type="dark">
          {store.user.firstName && store.user.firstName.slice(0, 1)}
          {store.user.lastName && store.user.lastName.slice(0, 1)}
        </HeaderButton>
      </div>
    );
  }

  if (store.user && store.user.isInstructor) {
    headerButtons = (
      <div>
        <HeaderButton onClick={searchForUsers}>
          <i aria-hidden className="far fa-magnifying-glass" />
        </HeaderButton>
        <HeaderButton to="/admin/schedule">
          <i className="far fa-calendar-check" />
        </HeaderButton>
      </div>
    );
  }

  const totalRegistrations =
    session.groupRegistrations.length +
    session.privateSeries.length +
    session.memberships.length;

  if (totalRegistrations > 0) {
    headerButtons = (
      <div>
        {store.isAdminArea && (
          <span className="mr-2">
            <HeaderButton onClick={searchForUsers}>
              <i aria-hidden className="far fa-magnifying-glass" />
            </HeaderButton>
          </span>
        )}
        <HeaderCartButton />
      </div>
    );
  }

  if (
    hideHeader.some((regex) => regex.test(location.pathname)) ||
    (SwimAmericaDomains.includes(store.organization.subdomain) &&
      swimAmericaHideHeader.some((regex) => regex.test(location.pathname)))
  ) {
    return (
      <>
        {store.user && store.user.isSalesOrAbove && (
          <div className="fixed left-0 top-0 z-40 w-full">
            <SuperAdminBar />
          </div>
        )}
      </>
    );
  }

  const isViewingAs = Boolean(store.user && store.superAdmin);
  const isSuperAdmin = store.user?.isSalesOrAbove;
  const isStaff = store.user?.isStaff;
  const reducedHeader =
    (location.pathname.indexOf("/signup") === 0 &&
      location.pathname !== "/signup/success") ||
    location.pathname === "/terms-of-service" ||
    location.pathname === "/privacy-policy";

  if (reducedHeader) {
    headerLink = "#";

    if (location.pathname === "/signup/success") {
      headerLink = "/admin";
    }
  }

  let departmentLabel = "All Departments";
  const departments = session.departments.filter(
    (d) => d.organization.id === store.organization.id
  );

  if (
    (!store.user?.isSalesOrAbove &&
      departments.length < store.user?.departments?.length) ||
    (store.user?.isSalesOrAbove &&
      departments.length < store.organization.departments.length)
  ) {
    departmentLabel = departments.map((d) => d.title).join(", ");
  }

  if (store.user?.departments?.length === 1 && !store.user?.isSalesOrAbove) {
    departmentLabel = store.user?.departments[0].title;
  }

  if (departmentLabel.length > 40) {
    departmentLabel = `${departments.length} Department${
      departments.length !== 1 ? "s" : ""
    }`;
  }

  if (departments.length === 0) {
    departmentLabel = `No Departments`;
  }

  return (
    <div
      className={tw(
        "fixed left-0 top-0 z-40 h-[50px] w-full bg-white shadow",
        isSuperAdmin && "h-[80px] pt-[30px]",
        isSuperAdmin && isViewingAs && "h-[110px]",
        !store.isAdminArea && !isStaff && ""
      )}
    >
      {!store.isAdminArea && !isStaff && (
        <div className="absolute left-0 top-0 z-50 h-[2px] w-full bg-organization"></div>
      )}

      {isSuperAdmin && (
        <div className="fixed left-0 top-0 z-40 w-full">
          <SuperAdminBar />
        </div>
      )}
      <Container full className="flex items-center gap-4">
        <div className="flex-1">
          {((reducedHeader && store.user) || !reducedHeader) && (
            <HeaderNavButton onClick={toggleMenu}>
              {ui.menuOpen ? (
                <i aria-hidden className="far fa-xmark" />
              ) : (
                <i aria-hidden className="far fa-bars" />
              )}
            </HeaderNavButton>
          )}

          <HeaderLogo to={headerLink}>{headerLogo}</HeaderLogo>
        </div>

        {store.organization.hasDepartments &&
          store.user?.isStaff &&
          !reducedHeader && (
            <div className="truncate text-sm text-empty sm:max-w-[130px]">
              {departmentLabel}
            </div>
          )}

        {!reducedHeader && <div className="">{headerButtons}</div>}
      </Container>
      {store.superAdmin && <ViewingAsHeader />}
    </div>
  );
}
