import {
  InfiniteData,
  keepPreviousData,
  QueryClient,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { DocumentNode, print } from "graphql";
import { getErrorString } from "src/utils/getErrorString";
import { getToken } from "src/utils/getToken";
import { TypedDocumentNode } from "types/graphql";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
    },
  },
});

interface UseApiInput<TData, TVariables> {
  queryKey?: QueryKey;
  variables: TVariables;
  headers?: { [k: string]: string };
  skip?: boolean;
  name?: string;
  token?: string;
  refetchOnWindowFocus?: boolean;
  refetchInterval?: number;
  onSuccess?: (data: TData) => void;
}

interface UseApiMutationInput<TData, TVariables = any>
  extends UseMutationOptions<TData> {
  variables?: TVariables;
  headers?: { [k: string]: string };
}

export type UseInfiniteApiResult<TData = any, TVariables = any> = Omit<
  UseInfiniteQueryResult<InfiniteData<TData>, TVariables>,
  "data"
> & {
  loading: boolean;
  count: number;
} & TData;

export type UseApiResult<TData = any, TVariables = any> = Omit<
  UseQueryResult<TData, TVariables>,
  "data"
> & {
  loading: boolean;
  count?: number;
} & TData;

export type UseApiMutationResult<TData, TVariables = any> = (args?: {
  variables: TVariables;
}) => Promise<TData>;

export function useApiMutation<TData = any, TVariables = any>(
  query: string | DocumentNode | TypedDocumentNode<TData, TVariables>,
  config?: UseApiMutationInput<TData, TVariables>
): UseApiMutationResult<TData, TVariables> {
  const mutationObject = useMutation<TData, any, { variables: TVariables }>({
    mutationFn: (args) => {
      // console.log("args", args);
      // console.log("config", config);

      const variables = {
        ...(args?.variables || {}),
        ...(config?.variables || {}),
      };

      // console.log("variables", variables);

      return fetchApi(query, { variables });
    },
  });
  // console.log("mutationObject", mutationObject);
  // const mutationFunction = ({ variables, config }) => {
  //   console.log("variables", variables);
  //   return mutationObject(variables);
  // };

  return function mutate(args) {
    return new Promise((resolve, reject) => {
      mutationObject.mutate(args, {
        onSuccess: (data, variables, context) => {
          resolve(data);
        },
        onError: (error, variables, context) => {
          reject(error);
        },
      });
    });
  };
}

export function useApi<TData = any, TVariables = any>(
  query: string | DocumentNode | TypedDocumentNode<TData, TVariables>,
  config: UseApiInput<TData, TVariables> = null
) {
  // : UseApiResult<TData, TVariables>
  const queryKey = getKey(query, config);

  const queryObject = useQuery<TData & { count?: number }, string>({
    queryKey,
    queryFn: async (): Promise<TData> => {
      return await fetchApi(query, config);
    },
    enabled: !config?.skip,
    refetchOnWindowFocus: config?.refetchOnWindowFocus,
    refetchInterval: config?.refetchInterval,
    // onSuccess: config?.onSuccess,
  });

  // console.log("queryObject", queryObject);
  // console.log("queryKey", queryKey);
  // console.log("queryObject.data", queryObject.data);

  return {
    loading: queryObject.isLoading && !queryObject.isRefetching,
    ...queryObject,
    data: null,
    count: queryObject.data?.count,
    ...queryObject.data,
  };
}

const getDocKey = (query: DocumentNode): string => {
  return (query.definitions[0] as any).name.value;
};

function getKey(query, config, infinite = false) {
  const operation = typeof query === "string" ? query : getDocKey(query);
  let key = [operation];

  if (config?.variables) {
    if (config.variables.filter) {
      key.push(config.variables.filter);
    }

    if (config.variables.where) {
      if (config.variables.where.id) {
        key.push(config.variables.where?.id);
      }

      if (config.variables.where.date) {
        key.push(config.variables.where.date.equals);
      }

      if (config.variables.where.organizationId) {
        key.push(config.variables.where.organizationId);
      }

      if (config.variables.where.q) {
        key.push(config.variables.where.q);
      }

      // key.push(JSON.stringify(config.variables.where));
    }

    if (config.variables.id) {
      key.push(config.variables.id);
    }

    // console.log("key", key);
  }

  if (infinite) {
    key.push("infinite");
  }

  if (config?.queryKey) {
    key = config.queryKey;
  }

  return key;
}

export function useInfiniteApi<TData = any, TVariables = any>(
  query: string | DocumentNode | TypedDocumentNode<TData, TVariables>,
  config: UseApiInput<TData, TVariables> = null
) {
  // UseInfiniteApiResult<TData, TVariables>
  const queryKey = getKey(query, config, true);

  // console.log("useInfiniteApi queryKey", queryKey);

  const queryObject = useInfiniteQuery<TData, string>({
    queryKey,
    initialPageParam: 0,
    queryFn: async (pageArgs): Promise<TData> => {
      // console.log("pageArgs", pageArgs);
      const variables = {
        ...config,
        variables: {
          ...config.variables,
          skip: pageArgs.pageParam || 0,
        },
      };

      // console.log("variables", variables);

      return await fetchApi(query, variables);
    },
    getNextPageParam: (lastPage, allPages) => {
      const mainKey = Object.keys(lastPage).filter((k) => k !== "count")[0];

      const items = allPages.reduce((acc, p) => {
        if (Array.isArray(p[mainKey])) {
          return acc.concat(p[mainKey]);
        } else {
          for (const k in p[mainKey]) {
            if (Array.isArray(p[mainKey][k])) {
              return acc.concat(p[mainKey][k]);
            }
          }
        }

        return acc;
      }, []);

      let param;
      const pageSize = (config.variables as any)?.take || 10;

      if (Array.isArray(lastPage[mainKey])) {
        param =
          lastPage[mainKey]?.length >= pageSize ? items.length : undefined;
      } else {
        for (const k in lastPage[mainKey]) {
          if (param) {
            break;
          }

          if (Array.isArray(lastPage[mainKey][k])) {
            param =
              lastPage[mainKey][k]?.length >= pageSize
                ? items.length
                : undefined;
          }
        }
      }

      // console.log("allPages", allPages);
      // console.log("lastPage", lastPage);
      // console.log("param", param);

      return param;
    },
    enabled: !config?.skip,
  });

  const data = queryObject.data?.pages?.reduce((acc, p) => {
    const mainKey = Object.keys(p).filter((k) => k !== "count")[0];

    if (Array.isArray(p[mainKey])) {
      if (!acc[mainKey]) {
        acc[mainKey] = [];
      }

      acc[mainKey] = acc[mainKey].concat(p[mainKey]);
    } else {
      if (!acc[mainKey]) {
        acc[mainKey] = {};
      }

      for (const k in p[mainKey]) {
        if (Array.isArray(p[mainKey][k])) {
          if (!acc[mainKey][k]) {
            acc[mainKey][k] = [];
          }
          acc[mainKey][k] = acc[mainKey][k].concat(p[mainKey][k]);
        } else {
          acc[mainKey][k] = p[mainKey][k];
        }
      }
    }

    return acc;
  }, {} as TData);

  // console.log("data", data);

  let count = 0;

  if (
    queryObject.data?.pages &&
    queryObject.data?.pages[0] &&
    queryObject.data?.pages[0]["count"]
  ) {
    count = queryObject.data?.pages[0]["count"];
  }

  return {
    ...queryObject,
    loading: queryObject.isLoading,
    count,
    ...data,
  };
}

export async function apiClient<TData, TVariables = any>(
  query: string | DocumentNode | TypedDocumentNode<TData, TVariables>,
  config: UseApiInput<TData, TVariables> = null
): Promise<TData> {
  const queryKey = getKey(query, config);
  // console.log("queryKey", queryKey);
  // console.log("queryKey", queryKey);
  return queryClient.fetchQuery({
    queryKey,
    queryFn: async () => fetchApi(query, config),
  });
  // const queryObject = useApi<TData, TVariables>(query, {
  //   ...config,
  //   enabled: false,
  // });
}

async function fetchApi<TData, TVariables = any>(
  query: string | DocumentNode | TypedDocumentNode<TData, TVariables>,
  config:
    | UseApiInput<TData, TVariables>
    | UseApiMutationInput<TVariables> = null
): Promise<TData> {
  // console.log("query", query);
  // console.log("config", config);

  const operation =
    typeof query === "string" ? query : getDocKey(query as DocumentNode);
  const url = `${process.env.NEXT_PUBLIC_APP_ENDPOINT}?op=${operation}`;
  const printedQuery =
    typeof query === "string" ? query : print(query as DocumentNode);
  const body = {
    query: printedQuery,
  };

  if (config?.variables && Object.keys(config.variables)?.length) {
    body["variables"] = config.variables;
  }

  try {
    let idToken = getToken();

    if (config?.variables?.token) {
      idToken = config.variables.token;
      delete body["variables"]["token"];
    }

    const headers = { "Content-Type": "application/json" };

    if (idToken) {
      headers["Authorization"] = `Bearer ${idToken}`;
    }

    if (config?.headers?.host) {
      headers["host"] = config?.headers?.host;
    }

    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });

    const count = response.headers.get("count")
      ? parseInt(response.headers.get("count"))
      : 0;
    const json = await response.json();

    if (json.errors?.length) {
      for (const e of json.errors) {
        throw new Error(e);
      }
    }

    const result = {
      ...json.data,
      count,
    };

    if (config?.onSuccess) {
      config.onSuccess(result, config.variables, config.headers);
    }

    return result;
  } catch (e) {
    console.error(e);
    // console.log("e.message", e.message);
    const error = getErrorString(e);

    if (
      error === "Unauthorized" &&
      (location?.pathname.includes("/admin") ||
        location?.pathname.includes("/super-admin") ||
        location?.pathname.includes("/account")) &&
      !location?.pathname.includes("/kiosk") &&
      !location?.pathname.includes("/cart") &&
      !location?.hostname.includes("demo.")
    ) {
      window.location.href = `/login?next=${location?.pathname}&reason=unauthorized`;
    }

    throw e;
  }
}

export function withApi(query, config) {
  return (Component) => {
    return function WrappedWithApi(props) {
      // console.log("config", config);
      const hocOptions = config.options ? config.options(props) : {};

      const queryKey = hocOptions?.queryKey
        ? hocOptions.queryKey
        : getKey(query, hocOptions);

      const queryObject = useApi(query, {
        ...config,
        variables: hocOptions?.variables,
        skip: config.skip ? config.skip(props) : false,
        queryKey,
      });

      const queryProps = {
        [config.name]: queryObject,
      };

      return <Component {...props} {...queryProps} />;
    };
  };
}

export function withApiMutation(query, config) {
  return (Component) => {
    return function WrappedWithApi(props) {
      const mutationFunction = useApiMutation(query, {
        variables: config?.options ? config.options(props).variables : {},
        ...config,
      });

      const mutationProps = {
        [config.name]: mutationFunction,
      };

      return <Component {...props} {...mutationProps} />;
    };
  };
}

export function withInfiniteApi(query, config) {
  return (Component) => {
    return function WrappedWithInfiniteApi(props) {
      const queryObject = useInfiniteApi(query, {
        variables: config.options ? config.options(props).variables : {},
        skip: config.options ? config.options(props).skip : false,
        queryKey: config.options ? config.options(props).queryKey : undefined,
      });

      const queryProps = {
        [config.name]: {
          ...queryObject,
          loading: queryObject.isLoading,
        },
      };

      return <Component {...props} {...queryProps} />;
    };
  };
}
